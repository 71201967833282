import React from "react";
import kayakKid from "./assets/images/KayakKid.jpg";
import SJShred from "./assets/images/SJShred.jpeg";
import JesseShred from "./assets/images/JesseShred.jpeg";
import AndyShred from "./assets/images/AndyShred.jpeg";
import HatShred from "./assets/images/HatShred.jpg";
import HartzellShred from "./assets/images/HartzellShred.jpg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>Shred Level One</p>
        <img src={kayakKid} className="App-logo" alt="logo" />
      </header>
      <div className="shredders-header">
        <h1>The Shredders</h1>
        <div className="shredders">
          <p>El Jefe</p>
          <p>Hat</p>
          <p>Swaim</p>
          <p>Handy Hartzell</p>
          <p>SJ</p>
        </div>
        <div className="shredders">
          <img src={JesseShred} className="shredder-image" alt="logo" />
          <img src={HatShred} className="shredder-image" alt="logo" />
          <img src={AndyShred} className="shredder-image" alt="logo" />
          <img src={HartzellShred} className="shredder-image" alt="logo" />
          <img src={SJShred} className="shredder-image" alt="logo" />
        </div>
      </div>
    </div>
  );
}

export default App;
